import API from "./apiUrls";
import axios from "axios";


const headerOptions = {

    headers: {
        "access-control-allow-origin": "http://google.com",
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
    }
}


const apiFunctions = {

    login: (body) => {
        return axios.post(`${API.logIn}`, body, {
            headers: {
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
                'Access-Control-Allow-Headers': '*',
                "Access-Control-Allow-Origin": "*",
            }
        })
    },
    forgotPassword: (body) => {
        return axios.post(`${API.forgotPassword}`, body)
    },
    logout: () => {
        return axios.get(`${API.logOut}`, headerOptions)
    },
    getTerms: () => {
        return axios.get(`${API.getTerms}`, headerOptions)
    },
    getSubstantAction: () => {
        return axios.get(`${API.getSubstantAction}`, headerOptions)
    },
    updateTerms: (body) => {
        return axios.put(`${API.updateTerms}`, body, headerOptions)
    },
    getPrivacy: () => {
        return axios.get(`${API.getPrivacy}`, headerOptions)
    },
    updatePrivacy: (body) => {
        return axios.put(`${API.updatePrivacy}`, body, headerOptions)
    },
    getLegal: () => {
        return axios.get(`${API.getLegal}`, headerOptions)
    },
    getAdminProfile: () => {
        return axios.get(`${API.getAdminProfile}`, headerOptions)
    },
    getGraphDetails: (body) => {
        return axios.get(`${API.getGraphDetails}/${body}`, headerOptions)
    },
    updateAdminProfile: (body) => {
        return axios.put(`${API.updateAdminProfile}`, body, headerOptions)
    },
    updateDistance: (body) => {
        return axios.put(`${API.updateDistance}`, body, headerOptions)
    },
    changePassword: (body) => {
        return axios.post(`${API.changePassword}`, body, headerOptions)
    },
    getAllUsers: (limit, searchKey, page) => {
        return axios.get(`${API.getAllUsers}?limit=${limit}&name=${searchKey}&page=${page}`, headerOptions)
    },
    getAllSlots: (limit, searchKey, page, dateId) => {
        return axios.get(`${API.getAllSlots}?limit=${limit}&name=${searchKey}&page=${page}&dateId=${dateId}`, headerOptions)
    },
    getAllEventAttend: (limit, searchKey, page, slotId) => {
        return axios.get(`${API.getAllEventAttend}?limit=${limit}&name=${searchKey}&page=${page}&slotId=${slotId}`, headerOptions)
    },
    getAllFeedback: (limit, searchKey, page) => {
        return axios.get(`${API.getAllFeedback}?limit=${limit}&search=${searchKey}&page=${page}`, headerOptions)
    },
    getAllNews: (limit, searchKey, page) => {
        return axios.get(`${API.getAllNews}?limit=${limit}&search=${searchKey}&page=${page}`, headerOptions)
    },
    getAllFaq: (limit, searchKey, page) => {
        return axios.get(`${API.getAllFaq}?limit=${limit}&search=${searchKey}&page=${page}`, headerOptions)
    },
    getAllTag: (limit, searchKey, page) => {
        return axios.get(`${API.getAllTag}?limit=${limit}&search=${searchKey}&page=${page}`, headerOptions)
    },
    getAllCountries: (limit, type, country_code) => {
        return axios.get(`${API.getAllCountries}?limit=${limit}&type=${type}&country_code=${country_code}`, headerOptions)
    },
    getAllSuggestions: (limit, searchKey, page) => {
        return axios.get(`${API.getAllSuggestions}?limit=${limit}&search=${searchKey}&page=${page}`, headerOptions)
    },
    uploadPhoto: (formData) => {
        return axios.post(`${API.uploadPhoto}`, formData, {
            headers: {
                'Content-Type': '',
                'Authorization': localStorage.getItem('token')
            }
        })
    },
    uploadGraphPhoto: (formData) => {
        return axios.post(`${API.uploadGraphPhoto}`, formData, {
            headers: {
                'Content-Type': '',
                'Authorization': localStorage.getItem('token')
            }
        })
    },
    uploadNewsPhoto: (formData) => {
        return axios.post(`${API.uploadNewsPhoto}`, formData, {
            headers: {
                'Content-Type': '',
                'Authorization': localStorage.getItem('token')
            }
        })
    },
    getAllEvents: (limit, searchKey, page, type) => {
        return axios.get(`${API.getAllEvents}?limit=${limit}&search=${searchKey}&page=${page}&type=${type}`, headerOptions)
    },
    getAllEventsDate: (id, limit, searchKey, page) => {
        return axios.get(`${API.getAllEventsDate}?limit=${limit}&search=${searchKey}&page=${page}&id=${id}`, headerOptions)
    },
    createEvent: (body) => {
        return axios.post(`${API.createEvent}`, body, headerOptions)
    },
    createNews: (body) => {
        return axios.post(`${API.createNews}`, body, headerOptions)
    },
    createFaq: (body) => {
        return axios.post(`${API.createFaq}`, body, headerOptions)
    },
    createTag: (body) => {
        return axios.post(`${API.createTag}`, body, headerOptions)
    },
    createSlot: (body) => {
        return axios.post(`${API.createSlot}`, body, headerOptions)
    },
    getUserProfile: (id) => {
        return axios.get(`${API.getUserProfile}/${id}`, headerOptions)
    },
    eventDetail: (id) => {
        return axios.get(`${API.eventDetail}/${id}`, headerOptions)
    },
    importEvents: (body) => {
        return axios.post(`${API.updateEvent}/import`, body, headerOptions)
    },
    getNews: (id) => {
        return axios.get(`${API.getNews}/${id}`, headerOptions)
    },
    getFaq: (id) => {
        return axios.get(`${API.getFaq}/${id}`, headerOptions)
    },
    getFeedback: (id) => {
        return axios.get(`${API.getFeedback}/${id}`, headerOptions)
    },
    getEventsDateDetail: (id) => {
        return axios.get(`${API.getEventsDateDetail}/${id}`, headerOptions)
    },
    addFriends: (body) => {
        return axios.put(`${API.addFriends}`, body, headerOptions)
    },
    updateUser: (id, body) => {
        return axios.put(`${API.updateUserProfile}/${id}`, body, headerOptions)
    },
    updateGraphDetails: (id, body) => {
        return axios.put(`${API.updateGraphDetails}/${id}`, body, headerOptions)
    },
    updateNews: (id, body) => {
        return axios.put(`${API.updateNews}/${id}`, body, headerOptions)
    },
    updateFaq: (id, body) => {
        return axios.put(`${API.updateFaq}/${id}`, body, headerOptions)
    },
    updateEvent: (id, body) => {
        return axios.put(`${API.updateEvent}/${id}`, body, headerOptions)
    },
    updateStatus: (id, body) => {
        return axios.patch(`${API.updateEvent}/${id}`, body, headerOptions)
    },
    updateEventsDate: (body) => {
        return axios.put(`${API.updateEventsDate}`, body, headerOptions)
    },
    updateSlots: (body) => {
        return axios.put(`${API.updateSlots}`, body, headerOptions)
    },
    blockUser: (body) => {
        return axios.put(`${API.blockUser}`, body, headerOptions)
    },
    removeUser: (body) => {
        return axios.put(`${API.removeUser}`, body, headerOptions)
    },
    removeSuggestion: (id) => {
        return axios.delete(`${API.removeSuggestion}/${id}`, headerOptions)
    },
    removeFeedback: (id) => {
        return axios.delete(`${API.removeFeedback}/${id}`, headerOptions)
    },
    removeNews: (id) => {
        return axios.delete(`${API.removeNews}/${id}`, headerOptions)
    },
    removeFaq: (id) => {
        return axios.delete(`${API.removeFaq}/${id}`, headerOptions)
    },
    removeTag: (id) => {
        return axios.delete(`${API.removeTag}/${id}`, headerOptions)
    },
    removeEvent: (body) => {
        return axios.delete(`${API.removeEvent}/${body._id}`, headerOptions)
    },
    deleteSlot: (body) => {
        return axios.delete(`${API.deleteSlot}/${body._id}`, headerOptions)
    },
    deleteDate: (body) => {
        return axios.delete(`${API.deleteDate}/${body.id}`, headerOptions)
    },
    addMoreSchedule: (body) => {
        return axios.post(`${API.addMoreSchedule}`, body, headerOptions)
    },
    getAllRemovedUsers: (limit, searchKey, page) => {
        return axios.get(`${API.getAllRemovedUsers}?limit=${limit}&name=${searchKey}&page=${page}`, headerOptions)
    },
    unFriend: (body) => {
        return axios.put(`${API.unFriend}`, body, headerOptions)
    },
    Groups: (body, limit, searchKey, page) => {
        return axios.get(`${API.Groups}/${body._id}`, headerOptions)
    },
    getAllFriendsAndGroups: (body, limit, searchKey, page) => {
        return axios.get(`${API.getAllFriendsAndGroups}?limit=${limit}&name=${searchKey}&page=${page}&_id=${body._id}`, headerOptions)
    },
    getGroupDetail: (id) => {
        return axios.get(`${API.getGroupDetail}/${id}`, headerOptions)
    },
    getSuggestionDetail: (id) => {
        return axios.get(`${API.getSuggestionDetail}/${id}`, headerOptions)
    },


}

export default apiFunctions;