import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import FileImport from './FileImport'
import apiFunctions from '../../api/apiFunctions';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ImportEventDialog = ({ open, handleClose }) => {

    const [data, setData] = useState([]);

    const handleFileLoad = (csvData) => {
        setData(csvData);
    };

    const navigate = useNavigate();

    const createEvents = async () => {
        // console.log("hellogetUserDeatil")
        const result = await apiFunctions.importEvents({ events: data });
        console.log(result, "result22222222222222222222222")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });

        } else {
            toast.success("Events imported successfully");
            setData([])
            handleClose();
        }
    }


    return (
        <Dialog
            PaperProps={{
                sx: { width: "80%", maxWidth: "900px" } // Adjust as needed
            }} onClose={handleClose} open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                {"Import special events"}
            </DialogTitle>
            <DialogContent>
                <FileImport onFileLoad={handleFileLoad} />
            </DialogContent>
            <DialogActions>
                {data.length > 0 && <div className="table-responsive table-bordered">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">source_id</th>
                                <th scope="col">title</th>
                                <th scope="col">latitude</th>
                                <th scope="col">longitude</th>
                                <th scope="col">map_url</th>
                                <th scope="col">place</th>
                                <th scope="col">country</th>
                                <th scope="col">country_code</th>
                                <th scope="col">state_code</th>
                                <th scope="col">world_rank</th>
                                <th scope="col">required_people</th>
                                <th scope="col">live_expected_people</th>
                                <th scope="col">annual_emission_of_co2</th>
                                {/* <th scope="col">event_type</th> */}
                                {/* <th scope="col">tag</th> */}
                                <th scope="col">sector_rank</th>
                                <th scope="col">special_notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => <tr key={index}>
                                <td scope="col">{item.source_id}</td>
                                <td scope="col">{item.title}</td>
                                <td scope="col">{item.latitude}</td>
                                <td scope="col">{item.longitude}</td>
                                <td scope="col">{item.map_url}</td>
                                <td scope="col">{item.place}</td>
                                <td scope="col">{item.country}</td>
                                <td scope="col">{item.country_code}</td>
                                <td scope="col">{item.state_code}</td>
                                <td scope="col">{item.world_rank}</td>
                                <td scope="col">{item.required_people}</td>
                                <td scope="col">{item.live_expected_people}</td>
                                <td scope="col">{item.annual_emission_of_co2}</td>
                                {/* <td scope="col">{item.event_type}</td> */}
                                {/* <td scope="col">{item.tag}</td> */}
                                <td scope="col">{item.sector_rank}</td>
                                <td scope="col">{item.special_notes}</td>
                            </tr>)}
                        </tbody>
                    </table>
                    <Button onClick={() => { createEvents() }}>Save Events</Button>
                </div>}
                {/* <Button onClick={() => { removeEvent(id) }} >Yes</Button> */}
            </DialogActions>
        </Dialog>
    )
}

export default ImportEventDialog