import Home from "../pages/home/home"
import TermOfUse from "../pages/content/TermsOfUse"
import Disclaimer from "../pages/content/disclaimer";
import Privacy from "../pages/content/privacy";
import Login from "../pages/login/login";
import ChangePassword from "../pages/profile/ChangePassword";
import Profile from "../pages/profile/Profile";
import RemovedUsers from "../pages/users/romovedUsers";
import UserProfile from "../pages/users/userProfile";
import Users from "../pages/users/Users";
import FriendOrGroup from "../pages/users/FriendOrGroup";
// import GroupDetails from "../pages/groupdetails/GroupDetails";
import Events from "../pages/Events/Events";
import AddEvent from "../pages/addEvent/AddEvent";
import EventDetail from "../pages/Events/EventDetail";
import SetDistance from "../pages/Events/SetDistance";
import EventsDateAndSlots from "../pages/Events/EventsDateAndSlots";
import CO2 from "../pages/Graphs/CO2";
import Temp from "../pages/Graphs/Temp";
import NewsList from "../pages/News/NewsList";
import NewsDetail from "../pages/News/NewsDetail";
import AddNews from "../pages/News/AddNews";
import ActionSubstant from "../pages/content/ActionSubstant";
import Faq from "../pages/faq/Faq";
import FaqDetail from "../pages/faq/FaqDetail";
import Suggestions from "../pages/suggestions/Suggestions";
import SuggestionDetail from "../pages/suggestions/SuggestionDetail";
import Feedbacks from "../pages/feedback/Feedbacks";
import FeedbackDetail from "../pages/feedback/FeedbackDetail";
import Tags from "../pages/tags/Tags";
import NormalEvent from "../pages/addEvent/NormalEvent";
import SlotsDetail from "../pages/Events/SlotsDetail";
import SlotsAnalytics from "../pages/Events/SlotsAnalytics";



const routes =  [
    {path: "/", Component: <Home/>},
    {path: "/login", Component: <Login/>},
    {path: '/home', Component: <Home/>},
    {path: '/terms_of_use', Component: <TermOfUse/>},
    {path: '/privacy_policy', Component: <Privacy/>},
    {path: '/legal_mention', Component: <Disclaimer/>},
    {path: '/substantiated', Component: <ActionSubstant/>},
    {path: '/profile', Component: <Profile/>},
    {path: '/change_password', Component: <ChangePassword/>},
    {path: '/users', Component: <Users/>},
    {path: '/home/users' ,  Component: <Users/> },
    {path: '/home/removed-users', Component: <RemovedUsers/>},
    {path: '/users/profile', Component: <UserProfile/>},
    {path: '/users/friendandgroup', Component: <FriendOrGroup/>},
    {path: '/home/CO2', Component: <CO2/>},
    {path: '/home/temp', Component: <Temp/>},
    {path: '/home/events', Component: <Events/>},
    {path: '/events', Component: <Events/>},
    {path: '/events/addevent', Component: <AddEvent/>},
    {path: '/events/detail', Component: <EventDetail/>},
    {path: '/home/setdistance', Component: <SetDistance/>},
    {path: '/home/events/events-date-and-slots', Component: <EventsDateAndSlots/>},
    {path: '/home/news', Component: <NewsList/>},
    {path: '/home/news/detail', Component: <NewsDetail/>},
    {path: '/home/news/addnews', Component: <AddNews/>},
    {path: '/home/news/addnews', Component: <AddNews/>},
    {path: '/home/faq', Component: <Faq/>},
    {path: '/home/faq/detail', Component: <FaqDetail/>},
    {path: '/home/suggestions', Component: <Suggestions/>},
    {path: '/home/suggestions/detail', Component: <SuggestionDetail/>},
    {path: '/home/feedbacks', Component: <Feedbacks/>},
    {path: '/home/feedbacks/detail', Component: <FeedbackDetail/>},
    {path: '/home/tags', Component: <Tags/>},
    {path: '/home/events/normal_events', Component: <NormalEvent/>},
    {path: '/home/events/slots', Component: <SlotsDetail/>},
    {path: '/home/events/slotsAnalytics', Component: <SlotsAnalytics/>},
]

export default routes;