import * as React from 'react';
import './App.css';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { toast, ToastContainer } from 'react-toastify'
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Routing from './routes/Routing';
import Sidebar from './components/Sidebar/Sidebar';
import Navbar from './components/Navbar/Navbar';
import Login from './pages/login/login';
import { Navigate, useLocation } from 'react-router-dom';
import { MenuOpen } from '@mui/icons-material';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: "#2f323b",
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));




export default function App() {
  const router = useLocation();
  // console.log(router)
  const [open, setOpen] = React.useState(true);
  const auth = localStorage.getItem("token")
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleResize = () => {
    if (window.innerWidth < 1020) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }
  React.useEffect(() => {
    window.addEventListener("resize", handleResize)
  })
  return (
    <>
           {router.pathname !== "/login"  &&  auth?(
        <Box sx={{
          display: 'flex',
          height: '100%'
        }}>
          <CssBaseline />
          <AppBar className="PanelTopBar"position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}  >
            <Toolbar  >
              {open === false
                ?
                <IconButton
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{ mr: 2, color: "#787878", ...(open && { display: 'none' }) }}
                >
                  <MenuIcon className='topBarIcon'/>
                </IconButton>
                :
                <IconButton
                  onClick={handleDrawerClose}
                  edge="start"
                  sx={{ mr: 2, color: "#787878" }}
                >
                  <MenuOpen  className='topBarIcon'/>
                </IconButton>
              }
              <Navbar />
            </Toolbar>
          </AppBar>
          
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
         
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <Divider />
            <IconButton onClick={handleDrawerClose}>
              <MenuOpen sx={{ position: 'left' }} />
            </IconButton>
            <Sidebar />
          </Drawer>
          <Main open={open}>
            
            <Routing />
          </Main>
        </Box>
           ):(<Login />)
        }
        
    </>
  )
}

