import { RemoveCircleOutline, RemoveRedEye, Search, Cancel } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, IconButton, } from '@mui/material'
import { styled } from '@mui/system'
import DeleteIcon from '@mui/icons-material/Delete';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/Loader/Loader'
import Toptag from '../../components/toptag/Toptag'
import "./events.css"
import apiFunctions from '../../api/apiFunctions'

const NavPaper = styled(Paper)({
    padding: "10px",
    borderRadius: "0",
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const BackPaper = styled(Paper)({
    height: "580px",
    overflow: "auto",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})




const EventsDateAndSlots = () => {
    const location = useLocation()
    const [all, setAll] = useState([]);
    const [isLoading, setIsloading] = useState(true)
    const [eventCount, setEventCount] = useState(0)
    const navigate = useNavigate();
    const [limit, setLimit] = useState(5);
    const [skip, setSkip] = useState('0');
    const [page, setPage] = useState("1")
    const [searchKey, setSearchKey] = useState("");
    const [openDialogForRemove, setOpenDialogForRemove] = useState(false);
    const [popupAddNewSchedule, setPopupAddNewSchedule] = useState(false)
    const [popupViewSchedule, setPopupViewSchedule] = useState(false)
    const [popupEditShedule, setPopupEditSchedule] = useState(false)
    const [isDateTimeEditable, setIsDateTimeEditable] = useState(false);
    const [isSlotsEditable, setIsSlotsEditable] = useState(false);
    const [popupAddSlots, setPopupAddSlots] = useState(false);
    const [newSchedule, setNewShedule] = useState({ date: '', slots: [{ startTime: '', endTime: '' }] })
    const [addSlots, setAddSlots] = useState([{ startTime: '', endTime: '' }])
    const [viewSchedule, setViewSchedule] = useState({})
    const [id, setId] = useState()
    const [err, setErr] = useState(false)

    const handleRemoveOpen = (id) => {
        setId(id)
        setOpenDialogForRemove(true);
    };

    const handleCloseAddSlotPopup = () => {
        setAddSlots([{ startTime: '', endTime: '' }])
        setErr(false)
        setPopupAddSlots(false)
    }

    const handleEditIconClick = (event_date_id) => {
        setId(event_date_id)
        getOne(event_date_id)
        setPopupEditSchedule(true);
    };

    const handleCloseEditIconClick = () => {
        setIsDateTimeEditable(false)
        setIsSlotsEditable(false)
        getAll()
        setPopupEditSchedule(false);
    };

    const showViewSchedulePopup = () => {
        setPopupViewSchedule(true);
    };

    const closeViewSchedulePopup = () => {
        setPopupViewSchedule(false);
    };

    const showAddNewSchedulePopup = () => {
        setPopupAddNewSchedule(true);
    };

    const closeAddNewSchedulePopup = () => {
        setNewShedule({ date: '', slots: [{ startTime: '', endTime: '' }] })
        setErr(false)
        setPopupAddNewSchedule(false);
    };

    const handleToggleEdit = () => {
        setIsDateTimeEditable(!isDateTimeEditable);
        getOne(id)
    };

    const handleDateTimeChange = (slotIndex, field, value) => {
        const updatedSlots = [...newSchedule.slots];
        updatedSlots[slotIndex][field] = value;
        setNewShedule({
            ...newSchedule,
            slots: updatedSlots,
        });
    };

    const handleSlotsChange = (slotIndex, field, value) => {
        const updatedSlots = [...viewSchedule.slots];
        updatedSlots[slotIndex][field] = value;
        setNewShedule({
            ...viewSchedule,
            slots: updatedSlots,
        });
    };

    const handleAddNewSlotsSchedule = (slotIndex) => {
        const updatedSlots = [...newSchedule.slots];
        updatedSlots.splice(slotIndex, 1);
        setNewShedule({
            ...newSchedule,
            slots: updatedSlots,
        });
    }

    const handleDeleteSlot = async (slotId) => {
        let result = await apiFunctions.deleteSlot({ _id: slotId })
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            getOne(result.data.data.events_date_id)
            setIsloading(false)
        }
    }

    const handleAddSlot = () => {
        const updatedSlots = [...newSchedule.slots];
        const newSlot = { startTime: '', endTime: '' };
        updatedSlots.push(newSlot);
        setNewShedule({
            ...newSchedule,
            slots: updatedSlots,
        });
    };

    const addSlotsToSchedule = async () => {

        if (addSlots.some(slot => !slot.startTime || !slot.endTime)) {
            setErr(true);
            return;
        }

        let result = await apiFunctions.createSlot({ event_id: viewSchedule.event_id, event_date_id: viewSchedule._id, slots: addSlots })
        console.log(result, "result add slots-------")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setPopupAddSlots(false)
            setAddSlots([{ startTime: '', endTime: '' }])
            getOne(viewSchedule._id)
            setIsloading(false)
        }
    }

    const handleSlotsValue = (slotIndex, field, value) => {
        const updatedSlots = [...addSlots];
        updatedSlots[slotIndex][field] = value;
        setAddSlots([...updatedSlots]);
    }

    const handleSlotsAddToEdit = () => {
        const updatedSlots = [...addSlots];
        const newSlot = { startTime: '', endTime: '' };
        updatedSlots.push(newSlot);
        setAddSlots([...updatedSlots]);
    }

    const handleDeleteSlotEdit = (slotIndex) => {
        const updatedSlots = [...addSlots];
        updatedSlots.splice(slotIndex, 1);
        setAddSlots([...updatedSlots]);
    }

    const handleSaveSchedule = async () => {

        if (!newSchedule.date || newSchedule.slots.some(slot => !slot.startTime || !slot.endTime)) {
            setErr(true);
            return;
        }

        let result = await apiFunctions.addMoreSchedule({ event_id: location.state.id, dateObj: newSchedule })
        console.log(result, "result to add more schedu;le")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setPopupAddNewSchedule(false);
            getAll()
            setIsloading(false)
        }
    };

    const getOne = async (event_date_id) => {
        let result = await apiFunctions.getEventsDateDetail(event_date_id);
        console.log(result, "result getEventsDateDetail--------------")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setViewSchedule(result.data.data)
            setIsloading(false)
        }
    }

    console.log(viewSchedule, "viewSchedule*9***********")


    const updateEventsDate = async (id) => {
        let result = await apiFunctions.updateEventsDate({ _id: id, dateObj: viewSchedule })
        console.log(result, "result updateEventsDate--------------")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            handleToggleEdit()
            setIsloading(false)
        }
    }

    const updateSlots = async () => {
        let result = await apiFunctions.updateSlots({ events_date_id: id, dateObj: viewSchedule })
        console.log(result, "result updateEventsDate--------------")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setIsSlotsEditable(false)
            setIsloading(false)
        }
    }

    const handleEyeIconClick = async (eventId) => {
        getOne(eventId)
        showViewSchedulePopup()
    };

    const handleRemoveClose = () => {
        setOpenDialogForRemove(false);
    };

    const searchHandle = (event) => {
        const newQuery = event.target.value;
        setSearchKey(newQuery)
        setSkip(0);
    }

    const getAll = async () => {
        let result = await apiFunctions.getAllEventsDate(location.state.id, limit, searchKey, page);
        console.log(result, "result888888888888888888888888")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setAll(result.data.data.data);
            setEventCount(result.data.data.total)
            setIsloading(false)
        }
    }

    useEffect(() => {
        getAll()
    }, [searchKey, limit, page])

    const removeEvent = async (dateTimeId) => {
        let result = await apiFunctions.deleteDate({ id: dateTimeId })
        console.log(result, "result to delete dateTime")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setOpenDialogForRemove(false);
            getAll();
            toast.success(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const addData = (name, value) => {
        console.log(name, "name")
        setNewShedule({
            ...newSchedule,
            [name]: value
        })
    }

    const addDataEdit = (name, value) => {
        console.log(name, "name")
        setViewSchedule({
            ...viewSchedule,
            [name]: value
        })
    }

    const handleChange = (e) => {
        if (e.target.value === eventCount) {
            setSkip(0)
            setLimit(eventCount)
        } else {
            setLimit(e.target.value)
        }
    };

    const skipPagination = (e, page) => {
        setSkip((page - 1) * limit)
        setPage(page)
    }

    const dateFucntion = (eventDate) => {
        let date = new Date(eventDate);
        let monthsArray = ["Jan", "Feb", "March", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec",];
        let joinedSince = `${date.getDate()} ${monthsArray[date.getMonth()]} ${date.getFullYear()}`;
        return joinedSince;
    }


    return (
        <>
            {isLoading ? (
                <Loader />) : (
                <>
                    <Toptag />
                    <ToastContainer autoClose={1000} />
                    <NavPaper>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div></div>
                            <Grid container spacing={4} direction="row" justifyContent="flex-end" alignItems="center">
                                <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Total Schedule - {eventCount}</div></Grid>
                                <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Row Per Page - </div></Grid>
                                <Grid item>
                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard-label"
                                            value={limit}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="5">5</MenuItem>
                                            <MenuItem value="10">10</MenuItem>
                                            <MenuItem value="20">20</MenuItem>
                                            <MenuItem value="30">30</MenuItem>
                                            <MenuItem value={eventCount}>All</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {/* <Grid item>
                                    <div className='searchBar'>
                                        <input type="text" placeholder='Search... ' onChange={searchHandle} id='searchtext' className='searchBarInput' />
                                        <Search className='searchIcon' />
                                    </div>
                                </Grid> */}
                                <Grid item>
                                    <button onClick={() => { showAddNewSchedulePopup() }} className='newBtn' >Add New Schedule</button>
                                </Grid>
                            </Grid>

                        </div>
                    </NavPaper>
                    <BackPaper>
                        {all.length === 0 && searchKey ?
                            <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                                <Grid item><img src="/images/nodata.jpg" alt="error-image" style={{ height: "150px", width: "200px" }} /></Grid>
                                <Grid item><div className="errorMessage">User not found!</div></Grid>
                            </Grid> :
                            <>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">S.No</TableCell>
                                            <TableCell align="center">Date</TableCell>
                                            <TableCell align="center">No. Of Slots</TableCell>
                                            {/* <TableCell>Country</TableCell> */}
                                            {/* <TableCell align="center">Subscription Status</TableCell> */}
                                            {/* <TableCell align="center">User Deletion</TableCell> */}
                                            <TableCell align="center">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {all.map((element, index) => {
                                            let joinedSince;
                                            let date = new Date(element.date);
                                            let monthsArray = ["Jan", "Feb", "March", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec",];
                                            joinedSince = `${date.getDate()} ${monthsArray[date.getMonth()]} ${date.getFullYear()}`;
                                            return (<>
                                                <TableRow key={index + 1}>
                                                    <TableCell align="center">{index + 1}</TableCell>
                                                    <TableCell align="center">{joinedSince}</TableCell>
                                                    <TableCell align="center">{element.slots.length}</TableCell>
                                                    {/* <TableCell>{truncateStringToWords(element.place, 30)}...</TableCell> */}
                                                    {/* <TableCell>{element.country}</TableCell> */}
                                                    {/* {element.dateTimes.map((slot)=>{
                                                    <TableCell>{slot.slots.startTime1} - {slot.slots.endTime1}     {slot.slots.startTime2} - {slot.slots.endTime2}
                                                    </TableCell>
                                                    })} */}
                                                    <TableCell align="center">
                                                        <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                                                            <Grid item>
                                                                <Tooltip title="Event's Schedule's Detail" >
                                                                    <RemoveRedEye className='visibilityIc'
                                                                        onClick={() => handleEyeIconClick(element._id)} />
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid item>
                                                                <Tooltip title="Slots Detail" >
                                                                <NavLink to='/home/events/slots' state={{ id: element._id }} ><AccessTimeIcon className='visibilityIc'/></NavLink>
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid item>
                                                                <Tooltip title="Edit Event Schedule's Detail" >
                                                                    <BorderColorIcon className='visibilityIc1'
                                                                        onClick={() => handleEditIconClick(element._id)} />
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid item>
                                                                <Tooltip title="Delete Event's Schedule" >
                                                                    <RemoveCircleOutline className="userListDelete" onClick={() => { handleRemoveOpen(element._id) }} />
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                            )

                                        })}
                                    </TableBody>
                                </Table>

                            </>
                        }


                        {/* dilogue for delete Schedule */}
                        <Dialog open={openDialogForRemove} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle id="alert-dialog-title">
                                {"Are you sure want to remove this Schedule from Event?"}
                            </DialogTitle>
                            <DialogActions>
                                <Button onClick={handleRemoveClose}>No</Button>
                                <Button onClick={() => { removeEvent(id) }} >Yes</Button>
                            </DialogActions>
                        </Dialog>



                        {/* dilogue for add newSchedule  */}
                        <Dialog open={popupAddNewSchedule} onClose={closeAddNewSchedulePopup}>
                            <DialogTitle>{"Add New Schedule To Events Date List"}</DialogTitle>
                            <DialogContent style={{ width: '550px' }}>
                                <div style={{ paddingTop: '10px', paddingBottom: '10px', }}>
                                    <div style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '10px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                            <div style={{ flex: 1, paddingRight: '10px', marginRight: '10px' }}>
                                                <label>Date:</label>
                                                <input type='date' name="date" style={{ marginLeft: '20px', paddingLeft: '5px', height: '30', width: '' }} value={newSchedule.date} onChange={(e) => { addData(e.target.name, e.target.value) }} />
                                            </div>
                                            <div>
                                                {err && newSchedule.date === '' ? <div className="errText">Required!</div> : null}
                                            </div>

                                            {/* Button to add new slot */}
                                            <div style={{ marginRight: '20px', marginBottom: '4px' }}>
                                                <Tooltip title="Add slot to schedule">
                                                    <AddCircleOutlineIcon variant="outlined" color="primary" onClick={() => handleAddSlot()}>+</AddCircleOutlineIcon>
                                                </Tooltip>
                                            </div>


                                        </div>
                                        <div>
                                            {newSchedule.slots.map((slot, slotIndex) => (
                                                <div key={slotIndex} style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                                                    <label>Start Time:</label>
                                                    <input type="time" style={{ width: "125px", marginLeft: '5px' }} value={slot.startTime} onChange={(e) => handleDateTimeChange(slotIndex, 'startTime', e.target.value)} />
                                                    <label style={{ marginLeft: "20px" }}>End Time:</label>
                                                    <input type="time" style={{ width: "125px", marginLeft: '5px' }} value={slot.endTime} onChange={(e) => handleDateTimeChange(slotIndex, 'endTime', e.target.value)} />

                                                    {/* Button to delete slot */}
                                                    <Tooltip title="Delete slot">
                                                        <DeleteIcon variant="outlined" color="error" style={{ marginLeft: "10px" }} onClick={() => handleAddNewSlotsSchedule(slotIndex)}>
                                                        </DeleteIcon>
                                                    </Tooltip>

                                                    <div>
                                                        {err && (slot.startTime === '' || slot.endTime === '') ? <div className="errText">Required!</div> : null}
                                                    </div>
                                                </div>

                                            ))}
                                        </div>

                                    </div>
                                </div>
                            </DialogContent>

                            <DialogActions>
                                <Button onClick={closeAddNewSchedulePopup} color="secondary">Close</Button>
                                <Button onClick={() => { handleSaveSchedule() }} color="primary">Save</Button>
                            </DialogActions>
                        </Dialog>




                        {/* dilogue for veiw Schedule user */}
                        <Dialog open={popupViewSchedule} onClose={closeViewSchedulePopup}>

                            <DialogTitle >
                                {"Schedule Of Events"}
                            </DialogTitle>
                            <DialogContent style={{ width: '550px' }}>
                                <div style={{ paddingTop: '10px', paddingBottom: '10px', }}>
                                    <div style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '10px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                            <div style={{ flex: 1, paddingRight: '10px', marginRight: '10px' }}>
                                                <label>Date:</label>
                                                <input type='text' name="date" style={{ marginLeft: '20px', paddingLeft: '5px', height: '30', width: '' }} value={dateFucntion(viewSchedule.date)} />
                                            </div>
                                        </div>
                                        {viewSchedule.slots && viewSchedule.slots.length > 0 && viewSchedule.slots.map((slot, slotIndex) => (
                                            <div key={slotIndex}>
                                                <label>Start Time:</label>
                                                <input type="time" style={{ width: "125px", marginLeft: '5px' }} value={slot.startTime} />
                                                <label style={{ marginLeft: "20px" }}>End Time:</label>
                                                <input type="time" style={{ width: "125px", marginLeft: '5px' }} value={slot.endTime} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeViewSchedulePopup} color="secondary">Close</Button>
                            </DialogActions>
                        </Dialog>

                        {/* dilogue for Edit Schedule */}
                        <Dialog open={popupEditShedule} onClose={handleCloseEditIconClick}>
                            <DialogTitle >
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    Edit Schedule
                                    <IconButton color="inherit" onClick={handleCloseEditIconClick}>
                                        <Cancel />
                                    </IconButton>
                                </div>
                            </DialogTitle>

                            <DialogContent style={{ width: '550px' }}>
                                {/* <div style={{ paddingTop: '10px', paddingBottom: '10px', }}>
                                    <div style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '10px' }}> */}
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                    <div style={{ flex: 1, paddingRight: '10px', marginRight: '10px' }}>
                                        <label>Date:</label>
                                        <input type={!isDateTimeEditable ? 'text' : 'date'} name="date" value={!isDateTimeEditable ? dateFucntion(viewSchedule.date) : viewSchedule.date} onChange={(e) => { addDataEdit(e.target.name, e.target.value) }} />
                                        <div>
                                            {err && viewSchedule.date === '' ? <div className="errText">Required!</div> : null}
                                        </div>
                                    </div>
                                    <DialogActions>
                                        {!isDateTimeEditable ?
                                            <Button variant="contained" onClick={() => setIsDateTimeEditable(true)}>
                                                Edit
                                            </Button> :
                                            <Button variant="contained" onClick={() => handleToggleEdit(false)}>
                                                Close Edit
                                            </Button>
                                        }
                                        {isDateTimeEditable ?
                                            <Button variant="contained" onClick={() => updateEventsDate(id)}>
                                                Save
                                            </Button> : null
                                        }
                                    </DialogActions>
                                </div>
                                <hr></hr>
                                {viewSchedule.slots && viewSchedule.slots.length > 0 && viewSchedule.slots.map((slot, slotIndex) => (
                                    <div key={slotIndex} style={{ paddingBottom: '8px' }}>
                                        <label>Start Time:</label>
                                        <input type={isSlotsEditable ? "time" : "text"} style={{ width: "125px", marginLeft: '5px' }} value={slot.startTime} onChange={(e) => handleSlotsChange(slotIndex, 'startTime', e.target.value)} />
                                        <label style={{ marginLeft: "20px" }}>End Time:</label>
                                        <input type={isSlotsEditable ? "time" : "text"} style={{ width: "125px", marginLeft: '5px' }} value={slot.endTime} onChange={(e) => handleSlotsChange(slotIndex, 'endTime', e.target.value)} />


                                        {/* Button to delete slot */}
                                        {isSlotsEditable ?
                                            <Tooltip title="Delete slot">
                                                <DeleteIcon variant="outlined" style={{ marginLeft: "10px" }} color="error" onClick={() => handleDeleteSlot(slot._id)}>
                                                </DeleteIcon>
                                            </Tooltip> : null}
                                    </div>
                                ))}
                                {/* </div>
                                </div> */}

                                {/* Button to add new slot */}
                                {isSlotsEditable ?
                                    <Button variant="outlined" color="primary" onClick={() => setPopupAddSlots(true)}>
                                        Add Slots
                                    </Button> : null}
                            </DialogContent>

                            <DialogActions>
                                {!isSlotsEditable ?
                                    <Button variant="contained" onClick={() => setIsSlotsEditable(true)}>
                                        Edit
                                    </Button> :
                                    <Button variant="contained" onClick={() => setIsSlotsEditable(false)}>
                                        Close Edit
                                    </Button>
                                }
                                {isSlotsEditable ?
                                    <Button variant="contained" onClick={() => updateSlots()}>
                                        Save
                                    </Button> : null
                                }
                            </DialogActions>
                        </Dialog>


                        {/* dilogue for add slots in Schedule */}
                        <Dialog open={popupAddSlots} >

                            <DialogTitle >
                                {"Add Slots Into Schedule"}
                            </DialogTitle>
                            <DialogContent>
                                {addSlots.map((slot, slotIndex) => (
                                    <div key={slotIndex} style={{ paddingBottom: '8px' }}>
                                        <label>Start Time:</label>
                                        <input type="time" value={slot.startTime} style={{ width: "125px", marginLeft: '5px' }} onChange={(e) => handleSlotsValue(slotIndex, "startTime", e.target.value)} />
                                        <label style={{ marginLeft: "20px" }}>End Time:</label>
                                        <input type="time" value={slot.endTime} style={{ width: "125px", marginLeft: '5px' }} onChange={(e) => handleSlotsValue(slotIndex, "endTime", e.target.value)} />
                                        <Tooltip title="Delete slot">
                                            <DeleteIcon variant="outlined" style={{ marginLeft: "10px" }} color="error" onClick={() => handleDeleteSlotEdit(slotIndex)}>
                                            </DeleteIcon>
                                        </Tooltip>
                                        <div>
                                            {err && (slot.startTime === '' || slot.endTime === '') ? <div className="errText">Required!</div> : null}
                                        </div>
                                    </div>
                                ))}
                                {/* Button to add new slot */}
                                <div style={{paddingTop : "15px"}}>
                                <Button variant="outlined" color="primary" onClick={() => handleSlotsAddToEdit()}>
                                    Add More Slot
                                </Button>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button color="secondary" onClick={() => { handleCloseAddSlotPopup() }}>Close</Button>
                                <Button color="secondary" onClick={() => { addSlotsToSchedule() }}>Add</Button>
                            </DialogActions>
                        </Dialog>

                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: 'center', marginTop: "20px", marginBottom: "20px" }}>
                            <div>
                                <Pagination count={Math.ceil(eventCount / limit)} page={Math.floor(skip / limit) + 1} variant="outlined" onChange={skipPagination} shape="rounded" />
                            </div>
                        </div>
                    </BackPaper>

                </>
            )
            }
        </>

    )
}

export default EventsDateAndSlots









