import { Block, Cancel, DeleteOutline, DoneAll, FreeBreakfastSharp, RemoveCircleOutline, RemoveRedEye, Search, UploadFile, WorkspacePremium } from '@mui/icons-material'
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import Diversity2Icon from '@mui/icons-material/Diversity2';
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/Loader/Loader'
import Toptag from '../../components/toptag/Toptag'
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import "./slots.css"
import apiFunctions from '../../api/apiFunctions'
import { elements } from 'chart.js'
import { URL } from '../../api/apiUrls'
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Legend } from 'chart.js';

// Register Chart.js components
// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


export default function SlotsAnalytics() {
    const [all, setAll] = useState([]);
    const [isLoading, setIsloading] = useState(true)
    const [usersCount, setUsersCount] = useState(0)
    const navigate = useNavigate();
    const [limit, setLimit] = useState(5);
    const [skip, setSkip] = useState('0');
    const [page, setPage] = useState("1")
    const [searchKey, setSearchKey] = useState("");
    const [openDialogForBlock, setOpenDialogForBlock] = React.useState(false);
    const [openDialogForRemove, setOpenDialogForRemove] = React.useState(false);
    const [id, setId] = useState()
    const [blockedStatus, setBlockedStatus] = useState()
    const location = useLocation();
    const [labels, setLabels] = useState([]);
    const [dataValues, setDataValues] = useState([]);

    const getAll = async () => {
        let result = await apiFunctions.getAllEventAttend(limit, searchKey, page, location.state.id);
        console.log(result, "result-------------------999999999")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setAll(result.data.data.data);
            setUsersCount(result.data.data.total_count)
            setIsloading(false)
            // Transform data for chart
            const eventLabels = all.map(event => event.createdAt);
            const attendanceData = all.length; // replace with your attendance field

            setLabels(eventLabels);
            setDataValues(attendanceData);
        }
    }

    useEffect(() => {
        getAll();
        
        // Clean up any existing charts before re-rendering
        return () => {
            ChartJS.getChart("myChart")?.destroy();
        };
    }, [navigate, location]);

    const data = {
        // labels,
        datasets: [
            {
                label: 'Attendance Count',
                // data: dataValues,
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: { position: 'top' },
            title: { display: true, text: 'Event Attendance' },
        },
    };
    return (
        <div>
            <h2>Event Attendance Analytics</h2>
            <Bar data={data} options={options} />
        </div>
    )
}
