import { Grid, Paper, Dialog, DialogContent, DialogTitle, Button, DialogActions, Switch } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate, useParams, NavLink } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import apiFunctions from '../../api/apiFunctions';
import Toptag from '../../components/toptag/Toptag'
import "./eventDetail.css"
import Loader from '../../components/Loader/Loader'



const BackPaper = styled(Paper)({
    height: "600px",
    borderRadius: "0",
    padding: "20px",
    overflow: "auto",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const EventDetail = ({ objs }) => {

    const location = useLocation();
    // console.log(location, "location---------------------")
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // make it true after editing
    const [edit, setEdit] = useState(false)
    const [err, setErr] = useState(false)
    const [place, setPlace] = useState('')
    const [obj, setObj] = useState({})
    const mapRef = useRef(null);
    const inputRef = useRef(null);
    const markersRef = useRef([]);
    const [eventTag, setEventTag] = useState([])
    // console.log(updatedObject, "name at a time when gettin userprofilew----------------")

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const initMap = () => {
        const map = new window.google.maps.Map(mapRef.current, {
            center: { lat: location.state.lat, lng: location.state.lng },
            zoom: 12,
        });
        const input = inputRef.current;
        const searchBox = new window.google.maps.places.SearchBox(input);
        map.addListener('bounds_changed', () => {
            searchBox.setBounds(map.getBounds());
        });
        searchBox.addListener('places_changed', () => {
            const places = searchBox.getPlaces();
            // console.log(places, "places")
            setPlace(places[0].formatted_address)
            setObj((prevObj) => ({
                ...prevObj,
                place: places[0].formatted_address,
                latitude: places[0].geometry.location.lat(),
                longitude: places[0].geometry.location.lng(),
                map_url: places[0].url
            }));
            if (places.length === 0) {
                return;
            }

            markersRef.current.forEach((marker) => {
                marker.setMap(null);
            });
            markersRef.current = [];
            const bounds = new window.google.maps.LatLngBounds();
            places.forEach((place) => {
                if (!place.geometry) {
                    // console.log('Returned place contains no geometry');
                    return;
                }
                markersRef.current.push(
                    new window.google.maps.Marker({
                        map: map,
                        title: place.name,
                        position: place.geometry.location,
                    })
                );
                if (place.geometry.viewport) {
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
            });
            map.fitBounds(bounds);
        });
    };

    // console.log(obj, "obj outseide mnapo-------------")
    // const imageChange = (e) => {
    //     setImage2(e.target.files[0])
    //     const file = e.target.files[0];
    //     if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/avif') {
    //         const reader = new FileReader();
    //         reader.readAsDataURL(file);
    //         reader.onloadend = () => {
    //             setImage(reader.result);
    //         };
    //     } else if (file.type !== 'image/jpeg' || file.type !== 'image/png') {
    //         setImage2("")
    //         setErr(true)
    //     } else {
    //         setErr(true)
    //     }

    // }

    const handlecChange = () => {
        setEdit(true)
    }

    const addata = (e) => {
        setErr(false)
        const { name, value } = e.target;
        if (name === 'world_rank' || name === 'required_people' || name === 'live_expected_people' || name === 'sector_rank') {
            setObj({
                ...obj,
                [name]: parseInt(value),
            });
        } else {
            setObj({
                ...obj,
                [e.target.name]: e.target.value,
            });
        }

    }


    const getEventDetail = async (previous) => {
        // console.log("hellogetUserDeatil")
        const result = await apiFunctions.eventDetail(location.state.id);
        console.log(result, "result22222222222222222222222")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });

        } else {
            setObj(result.data.data)
            setPlace(result.data.data.place)
            await delay(1000);
            setIsLoading(false)

        }
    }
    // console.log(obj, "objetttttttttttttttttttttttttttttttttttttttttttt+++++++++++")

    const updateEventDetail = async (event) => {
        const id = location.state.id;
        event.preventDefault();

        let eventObj;
        if (obj.title === '' || obj.place === '' || obj.country === '' || obj.annual_emission_of_co2 === '' || obj.event_tag === '' || obj.event_status === '' || obj.sector_of_pollution === '' || obj.source_id === '' || obj.event_type === '') {
            setErr(true);
        }
        else if (obj.place !== place) {
            setErr(true)
        }
        else {
            eventObj = {
                title: obj.title, place: obj.place, latitude: obj.latitude, longitude: obj.longitude, map_url: obj.map_url, country: obj.country, world_rank: obj.world_rank, required_people: obj.required_people, annual_emission_of_co2: obj.annual_emission_of_co2, sector_of_pollution: obj.sector_of_pollution, source_id: obj.source_id, sector_rank: obj.sector_rank, event_type: obj.event_type, tag: obj.tag, event_status: obj.event_status, special_notes: obj.special_notes
            }
        }
        console.log(err, "eventObj----------------")
        if (!err) {
            const result = await apiFunctions.updateEvent(id, eventObj);
            console.log(result, "resul update userobjext")
            if (!result.data.status) {
                if (result.data.code === 401) {
                    localStorage.clear();
                    navigate("/login")
                    toast.info("Session Expired Please login again!!")
                }
                toast.error(result.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000
                });
            } else {
                setIsLoading(false)
                setEdit(false)
                toast.success(result.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000
                });
            }
        }
    }

    const updateStatus = async (event) => {
        const id = location.state.id;
        event.preventDefault();
        const result = await apiFunctions.updateStatus(id, { event_status: event.target.checked ? "Active" : "Inactive" });
        console.log(result, "result update userobjext")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
        } else {
            getEventDetail();
            setIsLoading(false)
            setEdit(false)
            toast.success(result.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
        }
    }

    const getTags = async () => {
        let limit = 100
        let page = 1
        let search = ''
        let result = await apiFunctions.getAllTag(limit, search, page);
        console.log(result, "result-------------------999999999")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setEventTag(result.data.data);
        }
    }
    console.log(eventTag, "event---sdrserstaggatatgtagtgtgtfgtagtagatagtagtagta------------------------------")
    const event_tag = (id) => {
        let tagArr = eventTag.filter((obj) => obj._id == id);
        return tagArr[0].title
    }

    useEffect(() => {
        initMap()
        getEventDetail()
        getTags()
    }, [inputRef])


    return (
        <>
            <ToastContainer autoClose={1000} />
            {isLoading ?
                <Loader />
                :
                <>
                    <Toptag />
                    <ToastContainer />
                    <BackPaper>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item style={{ fontSize: "20px", fontWeight: "400", color: "grey" }}>Event Details</Grid>
                            <Grid item>
                                {!edit ? (
                                    <>
                                        <button className='newBtn' onClick={handlecChange}>Update</button>
                                        {/* <button className='newBtn' onClick={openDialog}>Add friends</button> */}
                                    </>) :
                                    null
                                }
                            </Grid>
                            <Grid item>
                                {edit ? <button className='newBtn' onClick={updateEventDetail}>Save</button> : null}
                            </Grid>
                        </Grid>
                        <hr></hr>

                        <Grid container spacing={2}>
                            <Grid xs={12} sm={12} md={6} lg={5} item >
                                <Grid container spacing={3} direction="column" justifyContent="flex-start" alignItems="center">
                                    <Grid item >
                                        <div id='map' ref={mapRef} style={{ height: '200px', width: "400px", marginLeft: "10px", marginTop: "8px" }} />
                                    </Grid>
                                    <Grid item style={{ width: "100%", paddingTop: "15px", paddingLeft: "30px" }}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Country</div>
                                            <div><input type="text" className="popInput1" name="country" id="country" disabled={!edit ? true : false} value={obj.country} onChange={addata} /></div>
                                            {err && obj.country === '' ? <div className="errText">Required!</div> : null}
                                        </div>
                                    </Grid>
                                    <Grid item style={{ width: "100%", paddingTop: "15px", paddingLeft: "30px" }}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Sector Of Pollution</div>
                                            <div><input type="text" className="popInput1" name="sector_of_pollution" id="sector_of_pollution" disabled={!edit ? true : false} value={obj.sector_of_pollution} onChange={addata} /></div>
                                            {err && obj.sector_of_pollution === '' ? <div className="errText">Required!</div> : null}
                                        </div>
                                    </Grid>
                                    <Grid item style={{ width: "100%", paddingTop: "15px", paddingLeft: "30px" }}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Event Status</div>
                                            <div><select className="popInput1" name="event_status" onChange={addata} value={obj.event_status || ''} disabled={!edit ? true : false}>
                                                <option value="Active">Active</option>
                                                <option value="Inactive">Inactive</option>
                                            </select>

                                                {err && obj.tag === '' ? <div className="errText">Required!</div> : null}</div>
                                            {/* {err && obj.sector_of_pollution === '' ? <div className="errText">Required!</div> : null} */}
                                        </div>
                                    </Grid>
                                    <Grid item style={{ width: "100%", paddingTop: "15px", paddingLeft: "30px" }}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Sector Rank</div>
                                            <div>
                                                <input type="number" className="popInput1" name="sector_rank" id="sector_rank" disabled={!edit ? true : false} value={obj.sector_rank} onChange={addata} /></div>
                                            {err && obj.sector_rank === '' ? <div className="errText">Required!</div> : null}
                                        </div>
                                    </Grid>
                                    <Grid item style={{ width: "100%", paddingTop: "15px", paddingLeft: "30px" }}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Country's World Rank</div>
                                            <div>
                                                <input type="number" className="popInput1" name="world_rank" id="world_rank" disabled={!edit ? true : false} value={obj.world_rank} onChange={addata} /></div>
                                            {err && obj.world_rank === '' ? <div className="errText">Required!</div> : null}
                                        </div>
                                    </Grid>
                                    <Grid item style={{ width: "100%", paddingTop: "15px", paddingLeft: "30px" }}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Annual Emission Of CO<sub>2</sub></div>
                                            <div>
                                                <input type="text" className="popInput1" name="annual_emission_of_co2" id="annual_emission_of_co2" disabled={!edit ? true : false} value={obj.annual_emission_of_co2} onChange={addata} /></div>
                                            {err && obj.annual_emission_of_co2 === '' ? <div className="errText">Required!</div> : null}
                                        </div>
                                    </Grid>
                                    <Grid item style={{ width: "100%", paddingTop: "15px", paddingLeft: "30px" }}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Special Notes</div>
                                            <div>
                                                <input type="text" className="popInput1" name="special_notes" id="special_notes" disabled={!edit ? true : false} value={obj.special_notes} onChange={addata} /></div>
                                            {/* {err && obj.special_notes === '' ? <div className="errText">Required!</div> : null} */}
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid><Grid xs={12} sm={12} md={6} lg={7} item >
                                <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-start" style={{ marginTop: "7px" }}>
                                    <Grid item style={{ width: "100%", marginLeft: "70px" }}>
                                        <div style={{ display: "flex", flexDirection: 'column', justifyContent: "flex-start", gap: "10px", alignItems: "flex-start", }}>
                                            <div className="popText">Place</div>
                                            <input type="text" ref={inputRef} placeholder="Enter Event location" className="popInputeventDetail" name="place" id="place" disabled={!edit ? true : false} value={obj.place} onChange={addata} /></div>
                                        {err && (obj.place === '' || obj.place !== place) ?
                                            <div className="errText">
                                                {obj.place === '' ? "Required!" : "Please select a valid place!"}
                                            </div>
                                            : null}
                                    </Grid>


                                    <Grid item style={{ width: "100%", marginLeft: "70px" }}>

                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Event Title</div>
                                            <div><input type="text" className="popInputeventDetail" name="title" id="title" disabled={!edit ? true : false} value={obj.title} onChange={addata} /></div>
                                            {err && obj.title === '' ? <div className="errText">Required!</div> : null}
                                        </div>

                                    </Grid>
                                    <Grid item style={{ width: "100%", marginLeft: "70px" }}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Date Of Event</div>
                                            <div>
                                                <NavLink to='/home/events/events-date-and-slots' state={{ id: obj._id }} > <button >View Event's Dates and Slots  </button></NavLink>
                                            </div>
                                            {err && obj.dateTimes.length === 0 ? <div className="errText">Required!</div> : null}
                                        </div>
                                    </Grid>
                                    <Grid item style={{ width: "100%", marginLeft: "70px" }}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Source Id</div>
                                            <div>
                                                <input type="number" className="popInputeventDetail" name="source_id" id="source_id" disabled={!edit ? true : false} value={obj.source_id} onChange={addata} /></div>
                                            {err && obj.source_id === '' ? <div className="errText">Required!</div> : null}
                                        </div>
                                    </Grid>
                                    <Grid item style={{ width: "100%", marginLeft: "70px" }}>

                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Event Type</div>
                                            <div><input type="text" className="popInputeventDetail" name="event_type" id="event_type" value={obj.event_type} readOnly /></div>
                                            {err && obj.event_type === '' ? <div className="errText">Required!</div> : null}
                                        </div>

                                    </Grid>
                                    <Grid item style={{ width: "100%", marginLeft: "70px" }}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Live Expected People</div>
                                            <div>
                                                <input type="number" className="popInputeventDetail" name="live_expected_people" id="live_expected_people" disabled={!edit ? true : false} value={obj.live_expected_people} readOnly /></div>
                                            {err && obj.live_expected_people === '' ? <div className="errText">Required!</div> : null}
                                        </div>
                                    </Grid>
                                    <Grid item style={{ width: "100%", marginLeft: "70px" }}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Required People</div>
                                            <div>
                                                <input type="number" className="popInputeventDetail" name="required_people" id="required_people" disabled={!edit ? true : false} value={obj.required_people} onChange={addata} /></div>
                                            {err && obj.required_people === '' ? <div className="errText">Required!</div> : null}
                                        </div>
                                    </Grid>
                                    <Grid item style={{ width: "100%", marginLeft: "70px" }}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Event Tag</div>
                                            <select className="popInput1" name="tag" onChange={addata} value={obj.tag || ''} disabled={!edit ? true : false}>
                                                <option value="">Select a Tag</option>
                                                {eventTag.map((tag) => (
                                                    <option key={tag._id} value={tag._id}>{event_tag(tag._id)}</option>  // Adjust `tag.id` and `tag.name` to match your data structure
                                                ))}
                                            </select>

                                            {err && obj.tag === '' ? <div className="errText">Required!</div> : null}
                                            {/* <div><input type="text" className="popInputeventDetail" name="tag" id="tag" disabled={!edit ? true : false} value={obj.tag} onChange={addata} /></div>
                                            {err && obj.tag === '' ? <div className="errText">Required!</div> : null} */}
                                        </div>

                                    </Grid>


                                    {obj.event_type === "Normal" && <Grid item style={{ width: "100%", marginLeft: "70px" }}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Event Status ({obj.event_status})</div>
                                            <Switch checked={obj.event_status === "Active"} onChange={updateStatus} />
                                        </div>
                                    </Grid>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </BackPaper>
                </>}

        </>
    )
}

export default EventDetail