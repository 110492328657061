import { Block, Cancel, DeleteOutline, DoneAll, FreeBreakfastSharp, RemoveCircleOutline, RemoveRedEye, Search, UploadFile, WorkspacePremium } from '@mui/icons-material'
import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, Dialog, DialogActions, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, useMediaQuery, useTheme, } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system'
import Diversity2Icon from '@mui/icons-material/Diversity2';
import "./tags.css"
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/Loader/Loader'
import Toptag from '../../components/toptag/Toptag'
import apiFunctions from '../../api/apiFunctions'



const NavPaper = styled(Paper)({
    padding: "10px",
    borderRadius: "0",
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const BackPaper = styled(Paper)({
    height: "580px",
    overflow: "auto",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

export default function Tags() {

    const [all, setAll] = useState([]);
    const [isLoading, setIsloading] = useState(true)
    const [usersCount, setUsersCount] = useState(0)
    const navigate = useNavigate();
    const [limit, setLimit] = useState(5);
    const [skip, setSkip] = useState('0');
    const [page, setPage] = useState("1")
    const [searchKey, setSearchKey] = useState("");
    const [openDialogForRemove, setOpenDialogForRemove] = React.useState(false);
    const [id, setId] = useState()
    const [create, setCreate] = useState(false)
    const [err, setErr] = useState(false)
    const [obj, setObj] = useState({
        title: ''
    })


    const handleRemoveOpen = (id) => {
        setId(id)
        setOpenDialogForRemove(true);
    };

    const handleRemoveClose = () => {
        setOpenDialogForRemove(false);
    };

    const searchHandle = (event) => {
        const newQuery = event.target.value;
        setSearchKey(newQuery)
        setSkip(0);
    }

    const handleUserOpen = () => {
        setCreate(true);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        let userObj = {
            title: obj.title,
        }

        const result = await apiFunctions.createTag(userObj);
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setCreate(false)
            getAll()
            // setTimeout(window.location.reload.bind(window.location), 10);
            toast.success("Tag created succesfully!")
        }

    };

    const addUserData = (e) => {
        const { name, value } = e.target;
            setObj({
                ...obj,
                [name]: value,
            });
    }

    const getAll = async () => {
        let result = await apiFunctions.getAllTag(limit, searchKey, page);
        console.log(result, "result-------------------999999999")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setAll(result.data.data);
            setUsersCount(result.data.total_count)
            setIsloading(false)
        }
    }

    useEffect(() => {
        getAll()
    }, [searchKey, limit, page])

    const removeTag = async (id) => {
        const result = await apiFunctions.removeTag(id);
        console.log(result, "result-------------------------")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setOpenDialogForRemove(false);
            getAll();
            toast.success(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const handleChange = (e) => {
        if (e.target.value === usersCount) {
            setSkip(0)
            setLimit(usersCount)
        } else {
            setLimit(e.target.value)
        }
    };

    const skipPagination = (e, page) => {
        setSkip((page - 1) * limit)
        setPage(page)
    }

    function truncateStringToWords(str, numWords) {
        const words = str.split('');
        const truncatedWords = words.slice(0, numWords);
        return truncatedWords.join('');
    }
  return (
    <>
    <div className='container'>
        {isLoading ? (
            <Loader />) : (
            <>
                <Toptag />
                <ToastContainer autoClose={1000} />
                <NavPaper>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div></div>
                        <Grid container spacing={4} direction="row" justifyContent="flex-end" alignItems="center">
                            <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Total faqs - {usersCount}</div></Grid>
                            <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Row Per Page - </div></Grid>
                            <Grid item>
                                <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard-label"
                                        value={limit}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="5">5</MenuItem>
                                        <MenuItem value="10">10</MenuItem>
                                        <MenuItem value="20">20</MenuItem>
                                        <MenuItem value="30">30</MenuItem>
                                        <MenuItem value={usersCount}>All</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <div className='searchBar'>
                                    <input type="text" placeholder='Search... ' onChange={searchHandle} id='searchtext' className='searchBarInput' />
                                    <Search className='searchIcon' />
                                </div>
                            </Grid>
                            <Grid item>
                                <button className='newBtn' onClick={handleUserOpen}>Add Tag</button>
                            </Grid>
                        </Grid>

                    </div>
                </NavPaper>
                <BackPaper>
                {all.length === 0 && searchKey ?
                            <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                                <Grid item><img src="/images/nodata.jpg" alt="error-image" style={{ height: "150px", width: "200px" }} /></Grid>
                                <Grid item><div className="errorMessage">Tags not found!</div></Grid>
                            </Grid> :
                            <>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>S.No</TableCell>
                                            <TableCell>Title</TableCell>
                                            <TableCell>Created Since</TableCell>
                                            {/* <TableCell>Created By Admin</TableCell> */}
                                            {/* <TableCell align="center">Subscription Status</TableCell> */}
                                            {/* <TableCell>Status</TableCell> */}
                                            {/* <TableCell align="center">User Deletion</TableCell> */}
                                            <TableCell align="center">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {all.map((element, index) => {
                                            let date = new Date(element.createdAt);
                                            let monthsArray = ["Jan", "Feb", "March", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec",];
                                            let joinedSince = `${date.getDate()} ${monthsArray[date.getMonth()]} ${date.getFullYear()}`
                                            return (<>
                                                <TableRow key={index + 1}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    {/* <TableCell>
                                                        {element.profilePhoto !== '' ? (
                                                            <img src={`${element.profilePhoto}`} alt="user" style={{ width: "40px", height: "40px", borderRadius: '50px' }} />
                                                        ) : (
                                                            <img src="/images/blank_pic.png" style={{ width: "40px", height: "40px", borderRadius: "50px" }} alt="" />

                                                        )}
                                                    </TableCell> */}
                                                  
                                                    <TableCell>{element.title}</TableCell>
                                                    <TableCell>{joinedSince}</TableCell>
                                                    {/* <TableCell align="center">
                                                        {element.isCreatedAdmin === true ?
                                                            <div className='adminCreation'>
                                                                <div><i className="fa-solid fa-user-tie" style={{ fontSize: "14px" }}></i></div>
                                                                <div> Administrator</div>
                                                            </div>
                                                            :
                                                            '...'
                                                        }</TableCell> */}
                                                    {/* <TableCell align="center">
                                                        {element.premiumStatus === false ?
                                                            <Chip label="free" className="statusChip" icon={<FreeBreakfastSharp className='FreeBreakfastSharp' />} />
                                                            : '...'
                                                        }
                                                    </TableCell> */}
                                                    {/* <TableCell>
                                                        {element.isVerified === false ? (
                                                            <Chip label="Not Verified" className="statusChipNonActive" />
                                                        ) : (
                                                            element.isBlocked === false ? (
                                                                <Chip label="Active" className="statusChipActive" />
                                                            ) : (
                                                                <Chip label="Blocked" className="statusChipBlock" />
                                                            )
                                                        )}
                                                    </TableCell> */}
                                                    {/* <TableCell align="center">{element.isDeleted === true ?
                                                        <div className='removedUser-Access'>
                                                            <div><i className="fa-solid fa-person-circle-xmark" style={{ fontSize: "18px" }}></i></div>
                                                            <div>Access Removed</div>
                                                        </div> :
                                                        '...'}</TableCell> */}
                                                    <TableCell>
                                                        <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                                                            {/* <Grid item>
                                                                <Tooltip title="view profile" >
                                                                    <NavLink to='/users/profile' state={{ id: element._id }} ><RemoveRedEye className='visibilityIc' /></NavLink>
                                                                </Tooltip>
                                                            </Grid> */}
                                                            {/* <Grid item>
                                                                <Tooltip title={!element.isBlocked ? 'block user' : 'unblock user'} >
                                                                    {element.isBlocked === true ?
                                                                        <Block className="blockIcon" style={{ color: "green" }} onClick={() => { handleBlockOpen(element._id, element.isBlocked) }} />
                                                                        :
                                                                        <Block className="blockIcon" style={{ color: "red" }} onClick={() => { handleBlockOpen(element._id, element.isBlocked) }} />
                                                                    }
                                                                </Tooltip>
                                                            </Grid> */}
                                                            <Grid item>
                                                                {element.isDeleted === false ?
                                                                    <Tooltip title="Delete User" >
                                                                        <RemoveCircleOutline className="userListDelete" onClick={() => { handleRemoveOpen(element._id) }}/>
                                                                    </Tooltip>
                                                                    : null}
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </>
                        }


                    {/* dilogue for delete user */}
                    <Dialog open={openDialogForRemove} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">
                            {"Are you sure want to remove this Tag?"}
                        </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleRemoveClose}>No</Button>
                            <Button onClick={() => { removeTag(id) }} >Yes</Button>
                        </DialogActions>
                    </Dialog>

                    {/* dilogue for create user */}
                    <Dialog open={create} aria-labelledby="responsive-dialog-title" >
                        <div className="popUpCreate">
                            <div style={{ display: 'flex', justifyContent: "flex-end", alignItems: 'center' }}>
                                <Cancel style={{ fontSize: "25px", color: "black", cursor: "pointer" }} onClick={() => setCreate(false)} />
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className='popHeading'>Create New Tag</div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", gap: "30px", alignItems: "center" }}>

                                <div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                        <div className="popText">Title</div>
                                        <div><input type="text" className="popInput" name="title" id="title" onChange={addUserData} /></div>
                                        {err && obj.title === '' ? <div className="errText">Title is required!</div> : null}
                                    </div>
                                </div>

                            </div>
                            {/* <div style={{ display: "flex", justifyContent: "center", gap: "30px", alignItems: "center" }}>

                                <div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                        <div className="popText">Answer</div>
                                        <div>
                                            <textarea type="text" className="popInput" name="answer" id="answer" onChange={addUserData} style={{ height: "150px" }} /></div>
                                        {err && obj.countryCode === '' ? <div className="errText">Answer is Required!</div> : null}
                                    </div>
                                </div>
                            </div> */}

                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "15px", marginBottom: "15px" }}>
                                <div>
                                    <button className='popBtn' onClick={handleSubmit}>Create</button>
                                </div>
                            </div>
                        </div>
                    </Dialog>

                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: 'center', marginTop: "20px", marginBottom: "20px" }}>
                        <div>
                            <Pagination count={Math.ceil(usersCount / limit)} page={Math.floor(skip / limit) + 1} variant="outlined" onChange={skipPagination} shape="rounded" />
                        </div>
                    </div>
                </BackPaper>

            </>
        )}
    </div>
</>
  )
}
